<template>
    <div>
    <v-btn @click="makeBulkPaymentAfterConfirmation" class="d-none" id="show-payment-model"></v-btn>
    <v-btn @click="exportDataForAppliedFilters" class="d-none" id="export-data"></v-btn>
    <v-btn @click="fetchData()" class="d-none" id="refresh-now"></v-btn>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
      <v-row>
        <v-col class="d-flex pl-6 pa-2 ma-0" cols="12" sm="12">
        <v-autocomplete
            v-model="financialYear"
            :items="years"
            item-value="year"
            @change="fetchData(); updateLocalStorage()"
            item-text="year"
            menu-props="auto"
            :label="$t('financial_year')"
            :outlined="is_boxes"
            :dense="is_boxes"
            class="mr-2 to-upper"
            :style="{maxWidth: '100px', width: '100px'}"
        ></v-autocomplete>
        <v-autocomplete
                flat
                :items="expensestatuses"
                v-model="status"
                :label="$t('billings.status')"
                class="mr-2 to-upper"
                item-value="id"
                item-text="text"
                @click:append="status = null; updateLocalStorage()"
                @change="updateLocalStorage"
                append-icon="mdi-close"
                return-object
                :outlined="is_boxes"
                :dense="is_boxes"
                hide-no-data
        ></v-autocomplete>
          <v-text-field
            class="mr-3 to-upper"
            :label="$t('billings.search')"
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            v-model="search"
            @click:append="search = null; updateLocalStorage()"
            @change="updateLocalStorage"
            :outlined="is_boxes"
            :dense="is_boxes"
          ></v-text-field>
          <v-autocomplete
            flat
            :items="clients"
            v-model="client"
            :label="$t('billings.client')"
            class="mr-2 to-upper"
            item-value="id"
            item-text="clientname"
            @click:append="client = null; updateLocalStorage()"
            append-icon="mdi-close"
            :search-input.sync="searchClient"
            :loading="isLoadingSearchClientInput"
            @change="updateLocalStorage"
            return-object
            :outlined="is_boxes"
            :dense="is_boxes"
            hide-no-data
          ></v-autocomplete>
          <!-- Payment Date -->
        <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    class="mr-2 to-upper"
                    v-model="search_payment_date"
                    :label="$t('billings.payed_at')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="search_payment_date = null;"
                    append-icon="mdi-close"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                    hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="search_payment_date"
                no-title
                scrollable
                :locale="userLanguageId"
                hide-no-data
                hide-details
                range
            ></v-date-picker>
        </v-menu>
        <!-- Start Date -->
        <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    class="mr-2 to-upper"
                    v-model="search_bill_date"
                    :label="$t('expenses.bill_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="search_bill_date = null;"
                    append-icon="mdi-close"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                    hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="search_bill_date"
                no-title
                scrollable
                :locale="userLanguageId"
                hide-no-data
                hide-details
                range
            ></v-date-picker>
        </v-menu>
        <!-- End Date -->
        <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            nude-right="0px" 
            transition="scale-transition"
            offset-y
            min-width="100px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    class="mr-2 to-upper"
                    v-model="search_due_date"
                    :label="$t('expenses.due_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:append="search_due_date = null;"
                    append-icon="mdi-close"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    hide-no-data
                    hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                :first-day-of-week="1"
                :weekday-format="getDay"
                v-model="search_due_date"
                no-title
                scrollable
                :locale="userLanguageId"
                hide-no-data
                hide-details
                range
            ></v-date-picker>
        </v-menu>
        </v-col>
      </v-row>
  
      <v-row>
        <v-data-table
          fixed-header
          :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
          :headers="headers"
          :hide-default-header="true"
          :items="expenses"
          :options="options"
          :server-items-length="totalItems"
          :loading-text="this.$t('loading')"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="identifier"
          @update:page="updatePagination"
          @update:items-per-page="updateItemsPerPage"
          style="width:100%"
          :height="'calc(-230px + 100vh)'"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :key="forceReloadComponentKey"
        >

            <template #header="{ props: { headers } }">
                <thead class="v-data-table-header">
                    <tr>
                        <template v-for="header in headers">
                            <th :key="header.value" scope="col" class="text-start pr-0 column" :style="getHeaderWidth(header, header.width)" v-bind:class="[header.sortable ? 'sortable' : '', sortBy == header.value ? 'active': '', sortOrd ? 'ASC':'DESC']" @click="header.sortable ? changeSort(header.value) : ''">
                                <template v-if="header.value == 'checkbox'">
                                    <v-row justify="space-between">
                                        <v-checkbox
                                            class="mt-1 ma-0 pa-0 ml-n3"
                                            hide-details="auto"
                                            v-model="isAllSelected"
                                        ></v-checkbox>
                                    </v-row>
                                </template>
                                <template v-else>
                                    {{ header.text }}
                                    <v-icon v-if="header.sortable" class="v-data-table-header__icon" small>
                                        <template v-if="sortOrd">
                                            mdi-arrow-up
                                        </template>
                                        <template v-else>
                                            mdi-arrow-down
                                        </template>
                                    </v-icon>
                                </template>
                            </th>
                        </template>
                    </tr>
                </thead>
            </template>
            <!-- <template v-slot:footer>
                <div style="position: absolute; width: 100%; font-size: 14px;" class="pa-0 pl-2 pt-4">
                    <tr>
                        <template v-for="header in headers">
                            <th :key="header.value" scope="col" class="text-start pr-0 column" :style="getHeaderWidth(header, header.footer_width)">
                                <template v-if="header.footer_value == 'total_thtva'">
                                    <div class="mr-1 ml-1" style="white-space: nowrap; color: blue;">
                                        {{ currencySymbol }}
                                    </div>
                                </template>
                                <template v-if="header.footer_value == 'total_tva'">
                                    <div class="mr-1 ml-1" style="white-space: nowrap; color: blue">
                                        {{ currencySymbol }}
                                    </div>
                                </template>
                                <template v-if="header.footer_value == 'total'">
                                    <div class="mr-1 ml-1" style="white-space: nowrap; color: blue">
                                        {{ currencySymbol }}
                                    </div>
                                </template>
                                <template v-if="header.footer_value == 'total_to_be_payed'">
                                    <div class="mr-1 ml-1" style="white-space: nowrap; color: red">
                                        {{ currencySymbol }}
                                    </div>
                                </template>
                                <template v-else>
                                    <span style="opacity: 0;">{{ header.text }}</span>
                                </template>
                            </th>
                        </template>
                    </tr>
                </div>
            </template> -->
          <template v-slot:item="{ item, index }">
            <tr
              :class="setRowColorClass(item, index)"
              :key="item.id"
            >
                <!-- Expense Row Number -->
                <td :class="index == 0 && showGridTotal ? 'grey-background' : ''" :style="index == 0 && showGridTotal ? {backgroundColor: '#f2f1ff8c'} : {}">
                    <v-row class="ma-0" style="text-align: center;">
                        <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                            {{ getRowIndex(index) }}
                        </template>
                        <template v-else>
                            <!--  -->
                        </template>
                    </v-row>
                </td>
                <!-- Expense Select For Payment -->
                <td class="pr-0 pl-0 " :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <div>
                        <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                            <template v-if="item &&  item.status && item.status.value == 'waiting'">
                                <v-row :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                                    <v-simple-checkbox
                                        @click="makePaymentMenu(item, item.is_selected)"
                                        class="pl-1"
                                        v-model="item.is_selected"
                                        color="primary"
                                        :ripple="false"
                                        style="width: 10px;"
                                    ></v-simple-checkbox>
                                </v-row>
                            </template>
                        </template>
                        <template v-else>
                            <v-row :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                            </v-row>
                        </template>
                    </div>
                </td>
                <!-- Edit Item -->
                <td class="pl-0 pr-0" v-if="checkIfComponentLoaded('RowClickButton')" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <span @click="editItem(item, index, true)"  v-if="(showGridTotal ? (index != 0) : true)">
                        <RowClickButton></RowClickButton>
                    </span>
                </td>
                <!-- Expense Bill/Attachments -->
                <td class="pa-0 ma-0" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <ul class="scroll pl-0"  v-if="item && item.attachments && item.attachments.length">
                            <template v-for="(file) in item.attachments">
                                <li class="list-item" :key="file" v-if="checkIfAttachmentIsBill(file, item.attachments, item)">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <v-icon class="attachment_icon_color" @click="viewAttachment(file)" right medium> {{ findFileIconWithDescription(file) }}  </v-icon>
                                            </div>
                                        </template>
                                        {{ file.title }}
                                    </v-tooltip>
                                </li>
                            </template>
                        </ul>
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Reference Client -->
                <td @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        {{ item.reference }}
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Bill Date -->
                <td @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        {{ item.bill_date ? formatDate(item.bill_date.substr(0, 10), 'dd/mm/yyyy') : item.bill_date }}
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Client -->
                <td @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <span v-if="item.client">
                            {{ item.client.name }}
                            {{ item.client.first_name ? item.client.first_name : "" }}
                        </span>
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Total THTVA -->
                <td align="right" @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        {{ showFormattedNumber(item.total_without_vat) }}
                    </template>
                    <template v-else>
                        <template v-if="isLoadingTotal">
                            <v-progress-circular color="primary" indeterminate :size="18" :width="2"></v-progress-circular>
                        </template>
                        <template v-else>
                            {{ showFormattedNumber(sum_of_total_without_vat) }}
                        </template>
                    </template>
                </td>
                <!-- Total TVA -->
                <td align="right" @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        {{ showFormattedNumber(item.vat_total) }}
                    </template>
                    <template v-else>
                        <template v-if="isLoadingTotal">
                            <v-progress-circular color="primary" indeterminate :size="18" :width="2"></v-progress-circular>
                        </template>
                        <template v-else>
                            {{ showFormattedNumber(sum_of_vat_total) }}
                        </template>
                    </template>
                </td>
                <!-- Total -->
                <td align="right" @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        {{ showFormattedNumber(item.total) }}
                    </template>
                    <template v-else>
                        <template v-if="isLoadingTotal">
                            <v-progress-circular color="primary" indeterminate :size="18" :width="2"></v-progress-circular>
                        </template>
                        <template v-else>
                            {{ showFormattedNumber(sum_of_total) }}
                        </template>
                    </template>
                </td>
                <!-- To Be Payed -->
                <td align="right" @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <span v-if="item.payments"> 
                            {{ showFormattedNumber(getTotalUnpaid(item.payments, item)) }}
                        </span>
                    </template>
                    <template v-else>
                        <template v-if="isLoadingTotal">
                            <v-progress-circular color="primary" indeterminate :size="18" :width="2"></v-progress-circular>
                        </template>
                        <template v-else>
                            {{ showFormattedNumber(sum_of_to_be_payed) }}
                        </template>
                    </template>
                </td>
                <!-- Payed By-->
                <td align="center" @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <span v-if="item.payments"> {{ getPaymentPayedMethods(item.payments) }}</span>
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Payed At -->
                <td @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <span v-if="item.payments && item.payments.length > 0"> 
                            {{ formatDate(item.payments[0].executed_at.substr(0, 10), 'dd/mm/yyyy') }}
                        </span>
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Due Date -->
                <td @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        {{ item.due_date ? formatDate(item.due_date.substr(0, 10), 'dd/mm/yyyy') : null }}
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Note -->
                <td align="center" @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <template v-if="item.message">
                            <v-tooltip bottom z-index="9999">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="warning" v-bind="attrs" v-on="on">
                                        mdi-note-alert
                                    </v-icon>
                                </template>
                                {{ item.message }}
                            </v-tooltip>
                        </template>
                    </template>
                    <template v-else>

                    </template>
                </td>
                <!-- Information -->
                <td @click="editItem(item, index)" :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <v-tooltip right z-index="9999">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="warning" v-bind="attrs" v-on="on">
                                mdi-information
                                </v-icon>
                            </template>
                            <v-list-item
                                class="pa-0 ma-0"
                                v-for="(articles, index) in item.details"
                                :key="index"
                            >
                                <template
                                v-if="
                                    articles.article &&
                                    articles.article.descriptions.length > 0
                                "
                                >
                                {{ articles.quantity }} X
                                {{ articles.article.descriptions[0].description }}
                                </template>
                                <template v-else>
                                <template v-if="articles && articles.text">
                                    {{ articles.quantity }} X {{ articles.text }}
                                </template>
                                </template>
                            </v-list-item>
                        </v-tooltip>
                    </template>
                    <template v-else>
                        <!--  -->
                    </template>
                </td>
                <!-- Status -->
                <td @click="editItem(item, index)"  :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                    <template v-if="(showGridTotal && index != 0) || (!showGridTotal && index >= 0)">
                        <v-progress-linear
                            v-if="item && item.status"
                            :color="getColorOnTheBasisOfStatus(item, item.total, getTotalPaid(item.payments, item).toFixed(getDecimalNumber()), item.status.value)"
                            height="20"
                            :value="item.status && item.status.key && (item.status.key.includes('waiting')) ? 65 : 100"
                            :striped="! checkIfItemIsPurchaseOrderOrBillOrCreditNote(item.type.key) ? checkIfItemIsClosedOrCancelled(item.status.key)  : false"
                        >
                            <v-row>
                                <v-col cols="12" sm="8" class=" pr-0"   >
                                    <span class="pl-1 text-white">
                                        {{ getPayedStatus(item.total, getTotalPaid(item.payments, item).toFixed(getDecimalNumber()), item.status.value) }}
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="4" class="pl-0 pr-0" v-if="item.status && item.status.key && (item.status.key.includes('waiting'))">
                                    <span>
                                        {{ calculateNoOfDaysLeftInDueDate(item.due_date) }}{{ $t("no_of_days_unit") }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-progress-linear>
                    </template>
                    <template v-else>
                        <v-row :class="index == 0 && showGridTotal ? 'grey-background' : ''">
                        </v-row>
                    </template>
                </td>
                <!-- <td v-if="companyIsPeppol" :style="{textAlign : 'center'}"> {{ determineBillPeppolStatus(item).message }} </td> -->
            </tr>
          </template>

        <template v-slot:footer>
            <div style="position: absolute" class="pa-0 pl-2 pt-7">
                <strong class="totalCalculation" v-if="totalSelected > 0">{{ $t('billings.total_in_listing', { total_selected: totalSelectedCount, currency_symbol: currencySymbol, total: totalSelected }) }} </strong>
            </div>
        </template>
        </v-data-table>
      </v-row>
        <BulkPayment
            v-if="showBulKPaymentModel"
            :showDialog.sync="showBulKPaymentModel"
            :paymentItems="selectedItemsForBulkPayment"
            :module="'expense'"
            @refreshList="fetchData"
        ></BulkPayment>

      <span id="zipped-reports" @click="launchZippedReportModal"><zipped-reports target="expenses" v-model="showZippedReports"></zipped-reports></span>

    </div>
  </template>
  
  <script>
  import axios from "@/plugins/axios";
  import _ from "lodash";
  import {API_BASE_URL, PEPPOL_BILL_TYPES} from "@/config";
  import { EXPENSE_LISTING_MODE } from "@/config";
  import { EDIT_MODE } from "@/config";
  import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
  import peppolMixin from "@/mixins/peppolMixin";
  import BulkPayment from '@/components/BulkPayment.vue';
  import ConfirmationModel from "@/components/ConfirmationModel";
  import ZippedReports from "@/components/ZippedReports";
  import zippedReportsMixin from "@/mixins/zippedReportsMixin";
import { DateTime } from "luxon";
import RowClickButton from "@/components/B7G/RowClickButton.vue";
import moment from "moment";

  export default {
    name: "ExpensesListing",
    mixins: [peppolMixin, zippedReportsMixin],
    components:{
        BulkPayment,
        ConfirmationModel,
        ZippedReports,
        RowClickButton
    },
    data() {
      return {
        financialYear: null,
        isLoadingTotal: false,
        sum_of_total_without_vat: 0,
        sum_of_vat_total: 0,
        sum_of_total: 0,
        sum_of_to_be_payed: 0,

        is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
        isLoading: true,
        totalItems: 0,
        lastPage: 1,
        sortBy: "id",
        sortDesc: true,
        sortOrd: null,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
        expenses: [],
        expenseType: null,
        search: null,
        // ftrByIdentifiant: "",
        ftrByCompany: "",
        date: null,
        menu_date: false,
        options: {},
        dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
        // header
        token: this.$store.state.AccessToken,
        header: {},
        companies: [],
        company: "",
        clients: [],
        client: "",
        nextEvents: [],
        nextEvent: "",
        allStatuses: [],
        expensestatuses: [],
        status: "",
        year: "",
        months: [],
        month: "",
        days: [],
        day: "",
        isPageLoaded: false,
        searchClient: null,
        stopSearch: true,
        menu1: false,
        menu2: false,
        menu3: false,
        search_bill_date: null,
        search_payment_date: null,
        search_due_date: null,
        total: 0,
        total_margin: 0,
        isLoadingSearchClientInput: false,
        showBulKPaymentModel: false,
        isAllSelected: false,

        confirmationDialog: false,
        confirmationDialogConfirmationText: null,
        confirmationDialogFalseText: null,
        confirmationDialogTrueText: null,
        confirmationDialogButtonClickedIs: null,
        confirmationDialogOperation: null,
        confirmationDialogData: null,
      };
    },
    mounted() {
        this.loadModuleOrComponent('RowClickButton');
        this.$store.state.apiAbortControllerMap = {};
      // this.search = '';
      this.fillMonths();
      (this.header = { Authorization: "Bearer " + this.token }),
        this.$store.commit("setFormMode", EXPENSE_LISTING_MODE);
      this.$store.commit("setAddButtonRoute", "AddClient");
      this.financialYear = this.years[0]?.year;
  
      this.getBillTypes().then(() => {
        this.getStatuses()
        .then(() => {
          this.expenseType = this.expensesTypes.find(expenseType => expenseType.key == "bill");
        })
        .then(() => {
        })
        .then(() => {
          this.setDefaultStatusAndSearch();
        })
        .then(() => {
            this.applyPreviousFilters();
            this.$nextTick(() => {
                this.isPageLoaded = true;
                this.stopSearch = false;
                    this.fetchData();
            });
        });
      })
    },
    methods: {
        exportDataForAppliedFilters(){
            // let expensesSelected = [];
            // this.expenses.forEach((element) => {
            //     if(element.is_selected){
            //         expensesSelected.push(element.id);
            //     }
            // })
            // console.log("ids selected", expensesSelected);
            // if(expensesSelected && expensesSelected.length > 0){
            //     this.fetchData(null, true, expensesSelected.join());
            // }
            // else{
                this.fetchData(null, true);
            // }

        },
        changeSort(column){
            if (this.sortBy === column) {
                this.sortOrd = !this.sortOrd;
            } else {
                this.sortBy = column;
                this.sortOrd = false;
            }
            this.updateLocalStorage();
        },
        viewAttachment(file){
            let url = file.signed_url;
            let fileName = file.title
            this.setPreviewData(url,fileName, true, file.mime_type, true, true);
            console.log("item selected clicked",file);
        },
        checkIfAttachmentIsBill(item, itemAttachments, row){
            let allBills = [];
            if(itemAttachments && itemAttachments.length > 0){
                let isBill = false;
                itemAttachments.forEach((attachment) => {
                    if( (attachment.description).toLowerCase() === 'bill' ){ // Its a bill
                        allBills.push(attachment.id);
                    }
                })
                return allBills.indexOf(item.id) == 0;
            }
            return false;
        },
        getRowIndex(index){
            let addExtraNumber = 1;
            if(this.showGridTotal){
                addExtraNumber = 0;
            }
            if(this.page != 1){
                return (this.page - 1 ) * this.itemsPerPage  + index + parseInt(addExtraNumber); 
            }
            return parseInt(index) + parseInt(addExtraNumber);
        },
        showPyamentModelForSelectedExpenses(){
            this.showBulKPaymentModel = true;
        },
        makePaymentMenu(){
            let expensesSelected = [];
            this.expenses.forEach((element) => {
                if(element.is_selected){
                    expensesSelected.push(element);
                }
            })

            // If Expenses Selected then only update the items
            if(expensesSelected && expensesSelected.length > 0){
                this.$store.commit("setSelectedItemsForBulkPayment", expensesSelected);
            }
            else{
                this.$store.commit("setSelectedItemsForBulkPayment", null);
            }
        },
        parseDate(date) {
            if (!date) return null;
            const [day, month, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        makeBulkPaymentAfterConfirmation() {
            this.confirmationDialogData = null;
            this.confirmationDialogConfirmationText = this.$t(
                "bulk_payments.confirmation_text"
            );
            this.confirmationDialogFalseText = this.$t("attachment.cancel");
            this.confirmationDialogTrueText = this.$t(
                "navigation.yes"
            );
            this.confirmationDialogOperation = "bulk_payment";
            this.confirmationDialog = true;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        getTotalUnpaid(paymentHistory, item){
            if(paymentHistory){
                let totalPayed = 0;
                    paymentHistory.forEach(element => {
                    totalPayed += this.customParseFloat(element.amount ?? 0);
                });
                return this.customParseFloat(item.total) - this.customParseFloat(totalPayed);
            }
            return this.customParseFloat(item.total);
        },
        getTotalPaid(paymentHistory, item){
            if(paymentHistory){
                let totalPayed = 0;
                    paymentHistory.forEach(element => {
                    totalPayed += this.customParseFloat(element.amount ?? 0);
                });
                return this.customParseFloat(totalPayed);
            }
            return 0;
        },
        getBillTypes() {
            return new Promise((resolve, reject) => {
            this.getCodes('order_types', 'billings.types')
                .then((data) => {
                    this.allStatuses = data;
                    data.forEach((element, index) => {
                        if (element.key == "bill") {
                            data[index].icon = "mdi-script-text";
                        } else if (element.key == "purchase_order") {
                            data[index].icon = "mdi-tag-text";
                        } else if (element.key == "estimation") {
                            data[index].icon = "mdi-book-open";
                        } else if (element.key == "credit_note") {
                            data[index].icon = "mdi-note-multiple";
                        }
                    });
                    this.$store.commit("setBillTypes", data);
                    resolve();
                });
                
            });
        },
        handleAutoFocus(event, maxlength, propertyName, dataType) {
            let currentFieldLength = 0;
            if (dataType == "int" || dataType == "float") {
            currentFieldLength = (event.target.value + "").replace(".", "").length;
            } else {
            currentFieldLength = (event.target.value + "").length;
            }
            if (
            propertyName == "day" &&
            maxlength != null &&
            currentFieldLength == maxlength
            ) {
            this.$refs["day"].blur();
            this.$refs["month"].focus();
            }
            if (
            propertyName == "month" &&
            maxlength != null &&
            currentFieldLength == maxlength
            ) {
            this.$refs["month"].blur();
            this.$refs["year"].focus();
            }
        },
        setRowColorClass(item,index) {
            let className = "";
            if (item.due_date_status == 0 && this.expenseType?.key == "bill") {
                className = "dueDate";
            }
    
            // if (item.payed_on != null && item.payed_by_id != null) {
            if(item.status && item.status.key == 'payed'){
                className = "greenColor";
            }
            if(this.showGridTotal && index == 0){
                className = "grey-background"
            }
            return className;
        },
        fillMonths() {
            // this.months = [{'id'  : "01",'text' : 'January'},{'id'  : "02",'text' : 'February'},{'id'  : "03",'text' : 'March'},{'id'  : "04",'text' : 'April'},{'id'  : "05",'text' : 'May'},{'id'  : "06",'text' : 'June'},{'id'  : "07",'text' : 'July'},{'id'  : "08",'text' : 'August'},{'id'  : "09",'text' : 'September'},{'id'  : "10",'text' : 'October'},{'id'  : "11",'text' : 'November'},{'id'  : "12",'text' : 'December'}];
            this.months = [
            { id: "01", text: "01" },
            { id: "02", text: "02" },
            { id: "03", text: "03" },
            { id: "04", text: "04" },
            { id: "05", text: "05" },
            { id: "06", text: "06" },
            { id: "07", text: "07" },
            { id: "08", text: "08" },
            { id: "09", text: "09" },
            { id: "10", text: "10" },
            { id: "11", text: "11" },
            { id: "12", text: "12" },
            ];
        },
        updateLocalStorage() {
            var filters = {
                items_per_page: this.itemsPerPage,
                sortBy: this.sortBy,
                sortOrd: this.sortOrd,
                search: this.search,
                // ftrByIdentifiant: this.ftrByIdentifiant,
                // company: this.company,
                expenseType: this.expenseType,
                client: this.client,
                status: this.status,
                search_due_date: this.search_due_date,
                search_bill_date: this.search_bill_date,
                search_payment_date: this.search_payment_date,
                financialYear: this.financialYear,
            };
            localStorage.setItem("EXPENSES_LISTING_FILTERS", JSON.stringify(filters));
        },
        applyPreviousFilters() {
            let applyOnRoutes = ["ExpensesListing"];
            console.log("expense_type_applying",applyOnRoutes, localStorage.getItem("NT_ROUTE_KEY"));
            if (applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY"))) {
                var expenseFilters = JSON.parse(
                localStorage.getItem("EXPENSES_LISTING_FILTERS")
                );

                if (expenseFilters != null) {
                    console.log("expense_type_applying",expenseFilters.expensesType);

                    this.items_per_page = expenseFilters.items_per_page;
                    this.sortBy = expenseFilters.sortBy;
                    this.sortOrd = expenseFilters.sortOrd;
                    this.search = expenseFilters.search;
                    // this.ftrByIdentifiant = expenseFilters.ftrByIdentifiant;
                    // this.company = expenseFilters.company;
                    console.log("expense_type_applying",expenseFilters.expensesType);
                    this.expenseType = expenseFilters.expenseType;
                    if (expenseFilters.client) {
                        (this.client = expenseFilters.client),
                        (this.clients = [this.client]);
                    }
                    this.status = expenseFilters.status;
                    this.search_due_date = expenseFilters.search_due_date;
                    this.search_bill_date = expenseFilters.search_bill_date;
                    this.search_payment_date = expenseFilters.search_payment_date;
                    this.financialYear = expenseFilters.financialYear;
                    
                }
            }
        },
        fetchData(page = null, is_export = false, ids = null) {
            this.resetDataTable(page);
            console.log("ids selected", ids);
            if (!this.stopSearch) {
                this.isLoading = true;
                if(!is_export){
                    this.expenses = [];
                }
                if(is_export){
                    this.$store.commit('setExportStatus', true);
                }
                let getRequest = 
                    API_BASE_URL +
                        "/expenses?page=" +
                        this.page +
                        "&items_per_page=" +
                        this.itemsPerPage +
                        (this.sortBy ? "&sortBy=" + this.sortBy : "") +
                        (this.sortOrd ? "&sortOrd=ASC" : "&sortOrd=DESC") +
                        (this.search ? "&search=" + encodeURIComponent(this.search) : "") +
                        // (this.ftrByIdentifiant ? "&document_number=" + this.ftrByIdentifiant : "") +
                        (this.company ? "&company_id=" + this.company.company_id : "") +
                        (this.client ? "&client_id=" + this.client.id : "") +
                        (this.status ? "&status_id=" + this.status.id : "") +
                        (this.expenseType ? "&type_id=" + this.expenseType.id : "") +
                        (this.search_bill_date ? "&bill_date=" + this.search_bill_date : "") +
                        (this.search_payment_date ? "&payment_date=" + this.search_payment_date : "") +
                        (this.search_due_date ? "&due_date=" + this.search_due_date : "") + 
                        (ids ? "&ids=" + ids : '') +
                        (this.financialYear ? "&financial_year=" + this.financialYear : "");
                axios
                    .get( getRequest +
                        (is_export ? "&is_export=1" : ''),
                    {
                        headers: this.header,
                        signal: this.abortController(),
                    }
                    )
                    .then(({ data }) => {
                        console.log("is_export", is_export,data);
                        if(is_export){
                            let reportDataInRowsColumns = [];
                            let dataRows = data.replace(/"/g, '').split("\n");
                            let finalData  = [];
                            let CSVkeysOrHeaders  = dataRows[0].split(";"); // Removing double Quotes and Splitting by SemiColon(;)
                            CSVkeysOrHeaders.unshift(this.$t('srno')); // Add Sr No to headers
                            console.log("CSV Headers", CSVkeysOrHeaders);
                            let count = -1;
                            let rownumber = 1;
                            dataRows.forEach((record) => {
                                count++;
                                if(count == 0){
                                    // return because first row will contain headers
                                    return
                                }
                                let dataInColumns = record.split(";");
                                let rowObject = {};
                                let rowColumnsNullCount = 0;
                                for (let index = 0; index < CSVkeysOrHeaders.length; index++) {
                                    if(CSVkeysOrHeaders[index] == this.$t('srno')){
                                        rowObject[CSVkeysOrHeaders[index]] = rownumber; // Add Sr No to key
                                    }
                                    else{
                                        rowObject[CSVkeysOrHeaders[index]] = dataInColumns[index-1]
                                    }
                                    if(rowObject[CSVkeysOrHeaders[index]] && CSVkeysOrHeaders[index] != this.$t('srno') ){
                                        rowColumnsNullCount++;
                                    }
                                }
                                console.log("record", rowColumnsNullCount, rowObject);
                                if(rowColumnsNullCount == 0){
                                    return;
                                }
                                finalData.push(rowObject);
                                rownumber++;
                            })
                            reportDataInRowsColumns = finalData;
                            this.totalItems = count - 1;
                            // if(this.totalItems > this.downloadCSVCountValue){
                            //     this.saveReport();
                            // }
                            console.log("data rows", dataRows,CSVkeysOrHeaders, reportDataInRowsColumns);
                            let currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
                            this.downloadCSVFromJson('Expenses_' + currentDateTime + ".csv", CSVkeysOrHeaders ,reportDataInRowsColumns); 
                        }
                        else{

                            console.log("Mark all expenses is selected to false", data.data);
                            data.data.forEach((element) => {
                                element.is_selected = false;
                            })
                            this.isAllSelected = false;

                            this.expenses = data.data;
                            if(this.showGridTotal){
                                this.expenses.unshift([]);
                            }
                            this.lastPage = data.meta.last_page;
                            this.totalItems = data.meta.total;
                            this.page = data.meta.current_page;
                        }

                        this.isLoading = false;
                        // Reset Selected Payment
                        this.$store.commit("setSelectedItemsForBulkPayment", null);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLoading = false;
                    })
                    .finally(() => {
                        this.stopSearch = false;
                        if(this.showGridTotal){
                            this.fetchGridTotal(getRequest);
                        }
                        this.$store.commit('setExportStatus', false);
                    });
            }
        },
        fetchGridTotal(getRequest) {
            this.isLoadingTotal = true;
            axios
                .get( getRequest +
                    (this.showGridTotal ? "&show_grid_total=1" : ''),
                    {
                        headers: this.header,
                    }
                )
                .then(({data}) => {
                    this.sum_of_total_without_vat = data.meta.sum_of_total_without_vat;
                    this.sum_of_vat_total = data.meta.sum_of_vat_total;
                    this.sum_of_total = data.meta.sum_of_total;
                    this.sum_of_to_be_payed = data.meta.sum_of_total_to_be_payed;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.isLoadingTotal = false;
                });
        },
        filterOutStatuses() {
            return new Promise((resolve, reject) => {
            if (this.expenseType && this.expenseType?.key == "estimation") {
                this.expensestatuses = this.allStatuses.filter((element) => {
                if (
                    this.listingStatusFilters.estimation.find((el) => {
                    return element.key == el;
                    })
                ) {
                    return element;
                }
                });
            } else if (this.expenseType && this.expenseType?.key == "purchase_order") {
                this.expensestatuses = this.allStatuses.filter((element) => {
                if (
                    this.listingStatusFilters.purchase_order.find((el) => {
                    return element.key == el;
                    })
                ) {
                    return element;
                }
                });
            } else if (this.expenseType && this.expenseType?.key == "bill") {
                this.expensestatuses = this.allStatuses.filter((element) => {
                if (
                    this.listingStatusFilters.bill.find((el) => {
                    return element.key == el;
                    })
                ) {
                    return element;
                }
                });
            } else if (this.expenseType && this.expenseType?.key == "credit_note") {
                this.expensestatuses = this.allStatuses.filter((element) => {
                if (
                    this.listingStatusFilters.credit_note.find((el) => {
                    return element.key == el;
                    })
                ) {
                    return element;
                }
                });
            }
            resolve();
            });
        },
        getStatuses() {
            return new Promise((resolve, reject) => {
                this.getCodes('order_status', 'billings.statuses')
                    .then((data) => {
                        this.allStatuses = data;
                        resolve();
                    })
            });
        },
        getCompanies() {
            return new Promise((resolve, reject) => {
            axios
                .get(API_BASE_URL + "/companies", { headers: this.header })
                .then(({ data }) => {
                console.log(data.data);
                this.companies = data.data.result.data;
                resolve();
                })
                .catch(function(error) {
                console.log("an error occured " + error);
                reject();
                });
            });
        },
        getNextEvents() {
            return new Promise((resolve, reject) => {
            this.getCodes('order_next_event', 'billings.nextEvents')
                    .then((data) => {
                        this.nextEvents = data;
                        resolve();
                    })
            });
        },
        updatePagination(page) {
            this.page = page;
            this.fetchData(page);
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
            this.fetchData();
            this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
        },
        editItem(item, index, forcedClick = false) {
            console.log("item clicked", item);
            if(((this.showGridTotal && index != 0) || (!this.showGridTotal && index >= 0)) && (!this.rowClickDisable || forcedClick)){
                this.$store.commit("seteditId", item.id);
                console.log(this.$store.state.editId, "commited Bill ID");
                this.$router.push("/expenses/edit").catch(() => {});
            }
        },
        deleteItem(item) {
            console.log("Delete Item");
        },
        setDefaultStatusAndSearch() {
            this.filterOutStatuses().then(() => {
                this.fetchData();
                //   if(this.expenseType && this.expenseType?.key === "bill") {
                //       this.status = this.expensestatuses.find( element => element.key === "waiting");
                //       return null;
                //   }
            });
        },
    },
    computed: {
        totalSelected() {
            let expensesSelectedTotal = 0;
            this.expenses.forEach((element) => {
                if (element.is_selected) {
                    expensesSelectedTotal = expensesSelectedTotal + parseFloat(element.total);
                }
            });
            return (expensesSelectedTotal).toFixed(this.getDecimalNumber());
        },
        rowClickDisable(){
            return this.checkIfComponentLoaded('RowClickButton');
        },
        dynamicLoadedComponents(){
            console.log("DynamicLoadedComponent",);
            return  Object.keys(this.$store.state.dynamicLoadedComponents);
        },
        navigationData(){
            return this.$store.state.navigationData;
        },
        totalSelectedCount(){
            let count = 0;
            this.expenses.forEach((element) => {
                if(element.is_selected){
                    count++;
                }
            })
            return count;
        },
        currencySymbol() {
            let symbol = null;
            if(this.$store.state.topCurrencyType){
                symbol = this.parseSymbol(this.$store.state.topCurrencyType.symbol ?? '')
                return symbol;
            }
            return symbol ?? ''; 
        },
        selectedItemsForBulkPayment(){
            return this.$store.state.selectedItemsForBulkPayment;
        },
      headers() {
        let data = [
          { text: "", value: "srno", width: 1, sortable: false, footer_width: 2 },
          { text: "", value: "checkbox", width: 1, sortable: false, footer_width: 2 },
          { text: "", value: "attachments", width: 1, sortable: false, footer_width: 2 },
          { text: this.$t("expenses.reference_client"), value: "reference", width: 2, sortable: true, align: 'left', footer_width: 2 },
          { text: this.$t("expenses.bill_date"), value: "bill_date", width: 5  , sortable: true , footer_width: 5},
          { text: this.$t("billings.client"), value: "client_name", width: 20, sortable: true, align: "start", footer_width: 26},              ////
          { text: this.$t("billings.totalThtva"), value: "total_price_thtva", width: 2, sortable: true, align: "end", footer_value: 'total_thtva', footer_width: 7},
          { text: this.$t("billings.totalTva"), value: "total_price_tva", width: 2, sortable: false, align: "end", footer_value: 'total_tva', footer_width: 4},
          { text: this.$t("billings.total"), value: "total_price_total", width: 2, sortable: false, align: "end", footer_value: 'total', footer_width: 7},
          { text: this.$t("expenses.to_be_payed"), value: "price_to_be_payed", width: 2, sortable: true, align: "end", footer_value: 'total_to_be_payed', footer_width: 3},
          { text: this.$t("billings.payed_by"), value: "payed_by_id", width: 5, sortable: false, align: "center"},
          { text: this.$t("billings.payed_at"), value: "payed_at", width: 5, sortable: false, align: "center"},
          { text: this.$t("expenses.due_date"), value: "due_date", width: 5, sortable: true, align: "start"},
          { text: this.$t("billings.note"), value: "note", width: 1, sortable: false, align: "center"},
          { text: this.$t("billings.info"), value: "id", width: 1, sortable: false},
          { text: this.$t("billings.status"),             value: "status_key",             width: '9%', sortable: true },

        ];

        if(this.rowClickDisable){
            data.splice(3,0,{
                text: "",           value: "itemClick",             width: 1,  sortable: false,footer_width: 1, align: "end"
            });
        }
  
          return data;
      },
    },
    watch: {
        navigationData: {
            handler: function () {
                this.selectDefaultExpenseTypeFromNavigationData();
            },
        },
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
                if (this.confirmationDialogOperation == "bulk_payment") {
                    if (this.confirmationDialogButtonClickedIs == true) {
                        this.showPyamentModelForSelectedExpenses();
                    }
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        isAllSelected(val){
            if(val){
                this.expenses.forEach((element) => {
                    if(element && element.status && element.status.value == 'waiting'){
                        element.is_selected = true;
                    }
                })
            }
            else{
                this.expenses.forEach((element) => {
                    element.is_selected = false;
                })
            }
            this.makePaymentMenu();
        },
        searchClient: _.debounce(function(e) {
            if(!this.searchClient){
                return
            }
  
            let searchTerm = this.searchClient.toString();
            searchTerm = searchTerm.trim()
            if (this.searchClient && this.searchClient.length > 0) {
                if (this.client && this.client.clientname == this.searchClient) {
                    return;
                }
  
                this.clients = [];
                this.isLoadingSearchClientInput = true;
                axios
                    .get(API_BASE_URL + "/clients/search?search=" + searchTerm, {
                        headers: this.header,
                    })
                    .then(({ data }) => {
                        data.data.forEach((element) => {
                            element.clientname =
                                element.name + " " + (element.first_name ?? "");
                        });
                        this.clients = data.data;
                    })
                    .catch(function(error) {
                        console.log("an error occured " + error);
                    })
                    .finally(() => {
                        this.isLoadingSearchClientInput = false;
                    });
            }
        }, 400),
        search: _.debounce(function(e) {
        if (this.isPageLoaded) {
          this.fetchData();
        }
      }, 1000),
      search_bill_date: _.debounce(function(e) {
        if (this.isPageLoaded) {
          this.updateLocalStorage();
          this.fetchData();
        }
      }, 100),
      search_payment_date: _.debounce(function(e) {
        if (this.isPageLoaded) {
          this.updateLocalStorage();
          this.fetchData();
        }
      }, 100),
      search_due_date: _.debounce(function(e) {
        if (this.isPageLoaded) {
            this.updateLocalStorage();
            this.fetchData();
        }
      }, 100),
    //   ftrByIdentifiant: _.debounce(function(e) {
    //     if (this.isPageLoaded) {
    //       this.fetchData();
    //     }
    //   }, 1000),
      company: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      client: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      status: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      sortOrd: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      sortBy: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      expenseType: {
        handler: function() {
            // Reset the status value
            this.status = null;

            if (this.isPageLoaded) {
                localStorage.removeItem("BILLING_LISTING_FILTERS");
                this.setDefaultStatusAndSearch();
            }
        },
      },
      date: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      year: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      day: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
      month: {
        handler: function() {
          if (this.isPageLoaded) {
            this.fetchData();
          }
        },
      },
    },
  };
  </script>
  
  <style scoped>
  tr:hover {
    border: 2px solid blue;
    color: #000;
    font-weight: 500;
  }
  tr.dueDate {
    background-color: rgb(255, 220, 220) !important;
  }
  tr.dueDate:hover {
    background: rgb(255, 153, 153) !important;
    border: 2px solid blue;
    color: #fff;
    font-weight: 500;
  }
  
  tr.greenColor {
    background-color: rgb(221, 255, 220) !important;
  }
  tr.greenColor:hover {
    background: rgb(153, 255, 162) !important;
    border: 2px solid blue;
    color: rgb(34, 34, 34);
    font-weight: 500;
  }
  
  tr.yellowColor {
    background-color: rgb(250, 255, 220) !important;
  }
  tr.yellowColor:hover {
    background: rgb(255, 240, 153) !important;
    border: 2px solid blue;
    color: rgb(39, 38, 38);
    font-weight: 500;
  }
  
  tr.redColor {
    background-color: rgb(255, 221, 220) !important;
  }
  tr.redColor:hover {
    background: rgb(255, 153, 153) !important;
    border: 2px solid blue;
    color: rgb(39, 38, 38);
    font-weight: 500;
  }
  /* .row_border{
    border-bottom: 2px solid rgba(133, 133, 133, 0.345) !important;
  } */
  </style>
  