import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

export default {
    data() {
        return {};
    },
    methods: {
        convertSelectedOrders() {
            if (
                this.selectedItemsForTransformTo &&
                this.selectedItemsForTransformTo.length > 0
            ) {
                let selectedOrderIds = this.selectedItemsForTransformTo.map((element) => {
                    return element.id;
                }).join(',');
                console.log("selectedOrderIds", selectedOrderIds);
                axios
                    .post(
                        API_BASE_URL +
                            "/billings/convert/" +
                            selectedOrderIds,
                        { id: selectedOrderIds },
                        { headers: this.header }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$store.commit("setSelectedItemsForTransformTo", null);
                            this.$toast.success(
                                this.$t(
                                    "billings.text_messages.converted_to_purchase_order_successfully"
                                )
                            );
                        } else {
                            this.$toast.error(
                                this.$t(
                                    "billings.text_messages.converted_to_purchase_order_failed"
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        this.$store.commit("setSelectedItemsForTransformTo", null);
                        this.$toast.error(
                            this.$t(
                                "billings.text_messages.converted_to_purchase_order_failed"
                            )
                        );
                    })
                    .finally(() => {
                        this.fetchData();
                    });
            }
        },
    },
    computed: {
        selectedItemsForTransformTo() {
            return this.$store.state.selectedItemsForTransformTo;
        },
    },
};
