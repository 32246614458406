<template>
  <v-dialog :value="value" max-width="1200" scrollable>
    <v-card>
      <v-card-title>

        <h3>{{ this.translate('title') }} ({{ this.translate(this.target) }})</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="getZippedAttachments">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row style="position: fixed;">
          <v-col :sm="2" >
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="year"
                    :label="translate('select_period')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="year"
                  type="year"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                  min="2020-01-01"
                  @change="(date) => $refs.menu.save(date)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col :sm="2">
            <v-select
                :items="billingStatuses"
                :value="selectedStatus"
                item-text="text"
                item-value="id"
                disabled
                readonly
                :label="$t('billings.status')"
                prepend-icon="mdi-filter"
            ></v-select>
          </v-col>
          <v-col :sm="2">
            <v-select
                :items="[selectedClient]"
                :value="selectedClient"
                item-text="name"
                item-value="id"
                disabled
                readonly
                :label="$t('billings.client')"
                prepend-icon="mdi-filter"
            ></v-select>
          </v-col>
          <v-col :sm="2">
            <v-text-field
              :label="translate('batch_size')"
              :hint="translate('batch_size_hint')"
              v-model.number="batchSize"
              @keypress="($event) => {
                if($event.keyCode < 48 || $event.keyCode > 57) $event.preventDefault()
              // if new value is greater than 200 prevent default
              if($event.target.value > 20 && $event.keyCode !== 8){
                batchSize = 200;
                $event.preventDefault()
              }
              }"
              >
            </v-text-field>
          </v-col>
          <v-col :sm="3" class="d-flex justify-space-around">
            <v-checkbox
                v-model="partialExport"
                :label="translate('checkbox_label')"
                hide-details
            ></v-checkbox>
          </v-col>

        </v-row>
        <v-list class="mt-16" style="overflow-y: auto;z-index: -1">
          <v-list-item v-for="item in attachments" :key="item.id">
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between">
                <a style="font-size: 1.3em" @click="download(item.public_url)">{{ item.description }}</a>
                <v-btn icon><v-icon color="red" small class="mr-2 mb-2" @click="launchDeleteConfirmation(item.id)">mdi-trash-can</v-icon></v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close"><CloseButton/></span>
        <v-btn class="ma-2" @click="launchConfirmation" color="success"> <v-icon class="mr-2">mdi-export</v-icon> {{ translate('export') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirmation-model
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import {API_BASE_URL} from "@/config";
import axios from "@/plugins/axios";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  name: 'ZippedReports',
  components: {CloseButton, ConfirmationModel},
  emits: ['input'],
  refs: ['menu'],
  props: {
    target: {
      type: String,
      default: 'orders'
    },
    value: {
      type: Boolean,
      default: false
    },
    selectedStatus: null,
    selectedClient: null,
  },
  data() {
    return {
      attachments: [],
      axiosOptions: {Authorization: "Bearer " + this.$store.state.AccessToken},
      type: null,
      year: null,
      yearPicker: false,
      menu: null,
      partialExport: true,
      confirmationDialog: false,
      confirmationDialogOperation: null,
      confirmationDialogConfirmationText: null,
      confirmationDialogTrueText: null,
      confirmationDialogFalseText: null,
      toBeDeleted: null,
      billingStatuses: [],
      batchSize: 200,
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.year = null
        this.partialExport = true
      }

      this.getZippedAttachments()
    },
  },
  async mounted(){
   this.billingStatuses = await this.getCodes('order_status', 'billings.statuses')
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    translate(key, args = {}) {
      return this.$t('zipped_reports_section.' + key, args)
    },
    getZippedAttachments() {
      return new Promise((resolve, reject) => {
        let url = API_BASE_URL + '/zipped_reports?target=' + this.target
        if (this.type) url += '&type=' + this.type

        
        axios.get(url, {headers: this.axiosOptions})
            .finally(
                () => {}
            )
            .then(response => {
              this.attachments = response.data.data
              resolve(this.attachments)
            })
            .catch(error => {
              reject(error)
            })
      })
    },
    download(url) {
      window.location.assign(url)
      this.$toast.success(this.translate('download_starting'));
    },
    launchConfirmation(){
      if(!this.year){
        return this.$toast.error(this.translate('year_not_selected'));
      }

      if(this.batchSize > 200 || this.batchSize < 10){
        return this.$toast.error(this.translate('batch_size_error'));
      }

      this.confirmationDialog = true
      this.confirmationDialogOperation = 'export'
      this.confirmationDialogConfirmationText = this.translate('confirmation_text')
      this.confirmationDialogTrueText = this.translate('confirmation_true_text')
      this.confirmationDialogFalseText = this.translate('confirmation_false_text')
    },

    launchDeleteConfirmation(deleteId){

      this.confirmationDialog = true
      this.confirmationDialogOperation = 'delete'
      this.confirmationDialogConfirmationText = this.translate('delete_confirmation_text')
      this.confirmationDialogTrueText = this.translate('confirmation_true_text')
      this.confirmationDialogFalseText = this.translate('confirmation_false_text')
      this.toBeDeleted = deleteId
    },
    confirmationButtonClicked(action) {
      if (!action) return

      if (this.confirmationDialogOperation === 'export') {
        this.exportAttachmentHandler()
      }

      if (this.confirmationDialogOperation === 'delete') {
        this.deleteAttachmentHandler()
      }
    },
    deleteAttachmentHandler() {
      axios.delete(API_BASE_URL + '/attachments/' + this.toBeDeleted, {headers: this.axiosOptions})
          .finally(() => {})
          .then(() => {
            this.$toast.success(this.translate('delete_success'));
            this.attachments = this.attachments.filter(item => item.id !== this.toBeDeleted)
          })
          .catch(error => {
            this.$toast.error(this.translate('delete_failed'));
          })
    },
    exportAttachmentHandler() {
      // this.$toast.success(this.translate('export_queued'));
      let payload = {
        date: this.year,
        target: this.target,
        partial_export: this.partialExport,
        client_id: this.selectedClient?.id || null,
        status_id: this.selectedStatus?.id || null,
        batch_size: this.batchSize
      }
        
      axios.post(API_BASE_URL + "/zipped_reports", payload, {headers: this.axiosOptions})
          .finally(() => {})
          .then(() => {
            this.$toast.success(this.translate('export_queued'));
            this.close();
          })
          .catch(error => {
            this.$toast.error(this.translate('export_failed'));
          })

    }

  }

}
</script>