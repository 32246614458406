<template>
  <v-card class="elevation-0">
    <v-tabs
        v-model="tab"
        height="50px"
        background-color="primary"
        dark
        v-if="checkTag('client_address') || checkTag('billing') || checkTag('vehicles') || checkTag('scheduler')"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#client-addresses" v-if="checkTag('client_address')">
        <v-icon>mdi-map-search-outline</v-icon>
        {{$t('clients.tabs.addresses')}}
      </v-tab>

      <v-tab href="#client-bills" v-if="checkTag('billing')">
        <v-icon>mdi-currency-eur</v-icon>
        {{$t('clients.tabs.bills')}}
      </v-tab>

      <v-tab href="#client-cars" v-if="checkTag('vehicles')">
        <v-icon>mdi-car-back</v-icon>
        {{$t('clients.tabs.vehicles')}}
      </v-tab>

      <v-tab href="#client-schedules" v-if="checkTag('scheduler')">
        <v-icon>mdi-calendar</v-icon>
        {{$t('clients.tabs.schedules')}}
      </v-tab>

      <v-tab href="#client-members" v-if="checkTag('clients') && client.representative_company_id">
        <v-icon>mdi-account-tie</v-icon>
        {{$t('clients.tabs.members')}}
      </v-tab>

      <v-tab href="#client-working-points" v-if="checkTag('clients') && client.representative_company_id">
        <v-icon>mdi-office-building-marker-outline</v-icon>
        {{$t('clients.tabs.working_points')}}
      </v-tab>

      <v-tab href="#webshop-history" v-if="hasWebshop">
        <v-icon>mdi-office-building-marker</v-icon>
        {{$t('clients.tabs.webshop')}}
      </v-tab>

      <!-- <v-tab href="#client-banks" v-if="checkTag('clients') && client.representative_company_id">
        <v-icon>mdi-bank</v-icon>
        {{$t('company_settings.tabs.bank_accounts')}}
      </v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item  :key="'client-addresses'" :value="'client-addresses'">
          <addresses-tab 
            v-if="tab == 'client-addresses'"
            :client="client"
          ></addresses-tab>
      </v-tab-item>

      <v-tab-item :key="'client-bills'" :value="'client-bills'">
          <bills-tab 
            v-if="tab == 'client-bills'"
            class="pa-4"
            :client="client"
          ></bills-tab>
      </v-tab-item>

      <v-tab-item :key="'client-cars'" :value="'client-cars'" >
          <vehicle-tab
            v-if="tab == 'client-cars'"
            :client="client"
          ></vehicle-tab>
      </v-tab-item>

      <v-tab-item :key="'client-schedules'" :value="'client-schedules'">
          <schedule-tab
            v-if="tab == 'client-schedules'"
            :client="client"
          ></schedule-tab>
      </v-tab-item>

      <v-tab-item v-if="client.representative_company_id" :key="'client-members'" :value="'client-members'">
          <client-members
            v-if="tab == 'client-members'"
            :client="client"
          ></client-members>
      </v-tab-item>

      <v-tab-item v-if="client.representative_company_id" :key="'client-working-points'" :value="'client-working-points'">
          <client-working-point
            v-if="tab == 'client-working-points'"
            :client="client"
          ></client-working-point>
      </v-tab-item>

      <v-tab-item key="webshop-history" value="webshop-history" v-if="hasWebshop">
        <v-row>
          <v-col md="2" class="mt-5">{{$t('clients.webshop_search_total')}} : {{totalSearch}}</v-col>
          <v-col md="2" class="mt-5"> {{$t('clients.webshop_total_order')}} : {{totalOrder}}</v-col>
          <v-col md="3">
            <v-menu
                v-model="fromDateToggle"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="fromDate"
                    :label="$t('scheduler.from_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="fromDate"
                  @input="fromDateToggle = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="3">
            <v-menu
                v-model="toDateToggle"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="toDate"
                    :label="$t('scheduler.to_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="toDate"
                  @input="toDateToggle = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-data-table
        :headers="tableHeadings"
        :items="searchHistory"
        :footer-props="{
            'items-per-page-options': dataTableOptions,
          }"
        :options="{
          'itemsPerPage': perPage,
          'page': page,
          'serverItemsLength': totalItems
        }"
        :server-items-length="totalItems"
        @update:page="(selectedPage) => {
          page = selectedPage
          getClientWebshopHistory(client.id)
        }"
        @update:items-per-page="(selectedItemPerPage) =>{
          perPage = selectedItemPerPage
          getClientWebshopHistory(client.id)
        }"
        >

        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AddressesTab from '@/components/Client/AddressesTab';
import BillsTab from '@/components/Client/BillsTab';
import VehicleTab from '@/components/Client/VehicleTab';
import ScheduleTab from '@/components/Client/ScheduleTab';
import ClientMembers from '@/components/Client/ClientMembers';
import ClientWorkingPoint from '@/components/Client/ClientWorkingPoint';
import axios from "@/plugins/axios";
import {API_BASE_URL, DEFAULT_ITEMS_PER_PAGE_OPTIONS} from "@/config";
import moment from "moment";

export default {
  name:       "ClientTabs",
  components: {
    'addresses-tab': AddressesTab,
    'bills-tab':     BillsTab,
    'vehicle-tab':     VehicleTab,
    'schedule-tab':     ScheduleTab,
    'client-members': ClientMembers,
    'client-working-point': ClientWorkingPoint
  },
  props:{
    client: [Object]
  },
  async mounted(){
   await this.getCompanyApplications();
    this.$nextTick(() => {
      if(this.hasWebshop){
        this.getClientWebshopHistory(this.client.id);
      }
    });
  },
  data() {
    return {
      tab:  null,
      text: "",
      company_applications: [],
      tableHeadings: [
        { text: this.$t('srno'), value: 'serial', sortable: false },
        { text: this.$t('date'), value: 'date', sortable: false },
        { text: this.$t('data'), value: 'processed_data', sortable: false },
      ],
      searchHistory: [],
      totalSearch: null,
      totalOrder: null,
      fromDate: null,
      toDate: null,
      fromDateToggle: false,
      toDateToggle: false,
      totalItems: null,
      perPage: 30,
      page: 1,
      dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS
    }
  },
  computed: {
    hasWebshop(){
      return this.company_applications.find( i => i.application.key === 'shop') !== undefined;
    },
    dateRange(){
      return {
        from: this.fromDate,
        to: this.toDate
      };
    },

  },
  watch: {
    dateRange: {
      handler: function (newVal, oldVal) {
        if(newVal.from && newVal.to && moment(newVal.from).isBefore(newVal.to)){
          this.getClientWebshopHistory(this.client.id);
        }
      },
      deep: true
    }
  },
  methods: {
    getCompanyApplications(){
      let companyID = localStorage.getItem("companyID");

      return new Promise((resolve, reject) => {
        axios
            .get(API_BASE_URL + "/companies/" + companyID + "/applications", {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              }
            })
            .then(({ data }) => {
              this.company_applications = data;
              resolve(this.company_applications);
            })
            .catch(function(error) {
              reject(error);
            })

      });
    },
    getClientWebshopHistory(clientId){

      return new Promise((resolve, reject) => {
        let url = API_BASE_URL + "/search_history/shop?client_id=" + clientId;
        // add pagination information
        url += "&page=" + this.page + "&per_page=" + this.perPage;

        if(this.fromDate && this.toDate){
          url += "&from_date=" + this.fromDate + "&to_date=" + this.toDate;
        }
        this.$store.state.isLoading = true;
        axios
            .get(url , {
              headers: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
              }
            })
            .finally(() => {
              this.$store.state.isLoading = false;
            })
            .then(({ data }) => {
              this.searchHistory = data.data.history;
              this.totalSearch = data.data.total_search;
              this.totalOrder = data.data.total_order;
              this.totalItems = data.total;
              resolve(this.searchHistory);
            })
            .catch(function(error) {
              reject(error);
            })

      });
    },
  },
}
</script>

<style scoped>

</style>
