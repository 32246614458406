var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}),_c('v-form',{ref:"form",staticClass:"main_form",attrs:{"id":"article-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-btn',{staticClass:"d-none",attrs:{"type":"submit","id":"submit-article"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.article_id),expression:"form.article_id"}],attrs:{"type":"hidden","name":"id"},domProps:{"value":(_vm.form.article_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "article_id", $event.target.value)}}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('label',{staticClass:"logoImage ma-4"},[(
                                (_vm.operation === 'edit' &&
                                    _vm.form.dynamicProperties &&
                                    _vm.form.dynamicProperties.identifier) ||
                                    _vm.operation != 'edit'
                            )?_c('img',{staticStyle:{"width":"150px","height":"150px","border":"3px dotted #efefef"},attrs:{"src":_vm.imageUrl},on:{"error":function($event){_vm.imageUrl = _vm.fallbackImageSrc}}}):_vm._e(),_c('input',{ref:"files",staticClass:"d-none",attrs:{"type":"file","id":"files","multiple":""},on:{"change":_vm.handleFileUpload}})])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',[(_vm.operation === 'edit')?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mr-2 mt-1 to-upper custom-readonly-color",attrs:{"autocomplete":_vm.autocompleteValueLocal,"flat":"","readonly":"","label":_vm.$t('articles.id'),"hide-details":"auto","outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.dynamicProperties.id),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, "id", $$v)},expression:"form.dynamicProperties.id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('v-select',{class:[
                                    _vm.operation == 'edit'
                                        ? 'mt-1 custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"readonly":_vm.operation === 'edit' &&
                                        !_vm.isGeneralDescriptionFormat,"items":_vm.globalfamilyArticles,"label":_vm.$t('articles.family'),"item-text":"name","item-value":"id","return-object":"","hide-details":"auto","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"click":function($event){return _vm.cacheCurrentData()},"change":function($event){return _vm.changeFamilyArticle(true)}},model:{value:(_vm.form.familyArticle),callback:function ($$v) {_vm.$set(_vm.form, "familyArticle", $$v)},expression:"form.familyArticle"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3","sm":"3"}},[_c('v-select',{staticClass:"mr-2",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.statuses,"label":_vm.$t('articles.status'),"item-value":"id","item-text":"text","hide-details":"auto","readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.dynamicProperties.status_id),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, "status_id", $$v)},expression:"form.dynamicProperties.status_id"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3","sm":_vm.operation == 'edit' ? 3 : 6}},[_c('v-select',{staticClass:"mr-2",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.approvals,"label":_vm.$t('articles.approval'),"item-value":"id","item-text":"text","hide-details":"auto","readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.dynamicProperties.approval_id),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, "approval_id", $$v)},expression:"form.dynamicProperties.approval_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mr-2 mt-2 to-upper",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"color":"teal","counter":"","maxlength":"13","label":_vm.form.familyArticle &&
                                    (_vm.form.familyArticle.parent_family_id ==
                                        1 ||
                                        this.form.familyArticle.id == 1)
                                        ? _vm.$t('articles.ean')
                                        : _vm.$t('articles.identifier'),"readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes,"error-messages":_vm.identifierError},on:{"input":function($event){_vm.identifierError = [];
                                    _vm.debounceHandler();}},model:{value:(_vm.form.dynamicProperties.identifier),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, "identifier", $$v)},expression:"form.dynamicProperties.identifier"}})],1),(!_vm.checkIfNamePropertyExists())?_c('v-col',[_c('v-text-field',{staticClass:"mr-2 mt-2 to-upper",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"error-messages":_vm.$store.state.validation &&
                                    !_vm.$v.form.dynamicProperties.name.required
                                        ? [
                                              this.$t(
                                                  'articles.text_messages.name_required'
                                              ) ]
                                        : [],"autocomplete":_vm.autocompleteValueLocal,"label":_vm.form.familyArticle &&
                                    (_vm.form.familyArticle.parent_family_id ==
                                        1 ||
                                        this.form.familyArticle.id == 1)
                                        ? _vm.$t('articles.profile')
                                        : _vm.$t('articles.name'),"hide-details":"auto","readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},model:{value:(_vm.form.dynamicProperties.name),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, "name", $$v)},expression:"form.dynamicProperties.name"}})],1):_vm._e(),_c('v-col',[_c('v-text-field-percent',{ref:"tableRedPercentage",staticClass:"mr-2 mt-2 to-upper",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('articles.tariff_price'),"readonly":!_vm.isEditGlobalArticleAllowed,"properties":{
                                    suffix: '',
                                    readonly: false,
                                    disabled: false,
                                    outlined: _vm.is_boxes,
                                    clearable: false,
                                    placeholder:
                                        _vm.getDecimalNumber() == 2
                                            ? '0.00'
                                            : '0.000',
                                    dense: _vm.is_boxes,
                                    hideDetails: 'auto',
                                },"options":{
                                    locale: _vm.userLanguageId,
                                    length: 8,
                                    precision: _vm.getDecimalNumber(),
                                    empty:
                                        _vm.getDecimalNumber() == 2 ? 0.0 : 0.0,
                                },"hide-details":"auto","tabindex":"4","focus":true},on:{"keypress":function($event){return _vm.handleInput($event, 'float', 10)}},model:{value:(
                                    _vm.form.dynamicProperties.tariff_price
                                ),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, "tariff_price", $$v)},expression:"\n                                    form.dynamicProperties.tariff_price\n                                "}})],1),_c('v-col',[_c('v-text-field-percent',{ref:"tableRedPercentage",staticClass:"mr-2 mt-2 to-upper",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('articles.price'),"readonly":!_vm.isEditGlobalArticleAllowed,"properties":{
                                    suffix: '',
                                    readonly: false,
                                    disabled: false,
                                    outlined: _vm.is_boxes,
                                    clearable: false,
                                    placeholder:
                                        _vm.getDecimalNumber() == 2
                                            ? '0.00'
                                            : '0.000',
                                    dense: _vm.is_boxes,
                                    hideDetails: 'auto',
                                },"options":{
                                    locale: _vm.userLanguageId,
                                    length: 8,
                                    precision: _vm.getDecimalNumber(),
                                    empty:
                                        _vm.getDecimalNumber() == 2 ? 0.0 : 0.0,
                                },"hide-details":"auto","tabindex":"4","focus":true},on:{"keypress":function($event){return _vm.handleInput($event, 'float', 10)}},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('v-col',[_c('v-text-field-percent',{staticClass:"mr-2 mt-2 to-upper",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('articles.plus_marge'),"readonly":!_vm.isEditGlobalArticleAllowed,"properties":{
                                    suffix: '',
                                    readonly: false,
                                    disabled: false,
                                    outlined: _vm.is_boxes,
                                    clearable: false,
                                    placeholder:
                                        _vm.getDecimalNumber() == 2
                                            ? '0.00'
                                            : '0.000',
                                    dense: _vm.is_boxes,
                                    hideDetails: 'auto',
                                },"options":{
                                    locale: _vm.userLanguageId,
                                    length: 8,
                                    precision: _vm.getDecimalNumber(),
                                    empty:
                                        _vm.getDecimalNumber() == 2 ? 0.0 : 0.0,
                                },"hide-details":"auto","tabindex":"4","focus":true},on:{"keypress":function($event){return _vm.handleInput($event, 'float', 10)}},model:{value:(
                                    _vm.form.plus_marge
                                ),callback:function ($$v) {_vm.$set(_vm.form, "plus_marge", $$v)},expression:"\n                                    form.plus_marge\n                                "}})],1),(_vm.form.familyArticle.is_stock_managed)?_c('v-col',{attrs:{"cols":"3","sm":"3"}},[_c('v-text-field',{staticClass:"mr-2 mt-2 to-upper",class:[
                                    !_vm.isEditGlobalArticleAllowed
                                        ? 'custom-readonly-color'
                                        : '' ],attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('articles.internal_stocks'),"hide-details":"auto","readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keypress":function($event){return _vm.handleInput($event, 'int', 8)}},model:{value:(_vm.form.internal_stocks),callback:function ($$v) {_vm.$set(_vm.form, "internal_stocks", $$v)},expression:"form.internal_stocks"}})],1):_vm._e()],1)],1)],1),_c('v-row'),_c('v-row'),_c('v-row',{staticClass:"d-flex"},_vm._l((_vm.filterPropertiesExcept(
                        _vm.customFilters
                    )),function(filter,index){return _c('v-col',{key:index},[(filter.property_name == 'property_00')?[_c('v-autocomplete',{ref:"brand",refInFor:true,staticClass:"mr-2 mt-1 to-upper",class:[
                                !_vm.isEditGlobalArticleAllowed
                                    ? 'custom-readonly-color'
                                    : '' ],staticStyle:{"min-width":"200px"},attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.brands,"label":_vm.$t('articles.properties.brand'),"item-value":"id","item-text":"name","hide-details":"auto","append-icon":"mdi-close","readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"focus":function($event){_vm.brand = null},"click:append":function($event){_vm.form.dynamicProperties.brand_id = null}},model:{value:(_vm.form.dynamicProperties.brand_id),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, "brand_id", $$v)},expression:"form.dynamicProperties.brand_id"}})]:[(filter.mapping_key != null)?_c('v-autocomplete',{staticClass:"mr-2 mt-1",class:[
                                !_vm.isEditGlobalArticleAllowed
                                    ? 'custom-readonly-color'
                                    : '' ],staticStyle:{"min-width":"200px"},attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.multiselectsValues[index],"label":filter.text,"item-value":"id","hide-details":"auto","append-icon":"mdi-close","readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"click:append":function($event){_vm.form.dynamicProperties[
                                    filter.property_name
                                ] = null}},model:{value:(
                                _vm.form.dynamicProperties[filter.property_name]
                            ),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, filter.property_name, $$v)},expression:"\n                                form.dynamicProperties[filter.property_name]\n                            "}}):_vm._e(),(filter.mapping_key == null)?_c('v-text-field',{ref:filter.property_name,refInFor:true,staticClass:"to-upper",class:[
                                !_vm.isEditGlobalArticleAllowed
                                    ? 'custom-readonly-color'
                                    : '' ],staticStyle:{"min-width":"200px"},attrs:{"autocomplete":_vm.autocompleteValueLocal,"flat":"","label":filter.text,"hide-details":"auto","readonly":!_vm.isEditGlobalArticleAllowed,"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"focus":function($event){filter.value = null},"keypress":function($event){return _vm.handleInput(
                                    $event,
                                    filter.keytype,
                                    filter.search_length,
                                    filter.keytype == 'float' ? 2 : 0
                                )}},model:{value:(
                                _vm.form.dynamicProperties[filter.property_name]
                            ),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, filter.property_name, $$v)},expression:"\n                                form.dynamicProperties[filter.property_name]\n                            "}}):_vm._e()]],2)}),1),_vm._l((_vm.articleBooleanProperties),function(row,rowIndex){return _c('v-row',{key:'R' + rowIndex,staticClass:"mt-2"},_vm._l((row),function(property,colIndex){return _c('v-col',{key:'C' + colIndex,staticClass:"ml-2 pa-0"},[_c('v-checkbox',{class:[
                            !_vm.isEditGlobalArticleAllowed
                                ? 'custom-readonly-color'
                                : '' ],attrs:{"label":_vm.$t('articles.properties.' + property.name),"readonly":!_vm.isEditGlobalArticleAllowed},model:{value:(
                            _vm.form.dynamicProperties[property.property_name]
                        ),callback:function ($$v) {_vm.$set(_vm.form.dynamicProperties, property.property_name, $$v)},expression:"\n                            form.dynamicProperties[property.property_name]\n                        "}})],1)}),1)})],2)],1),_c('v-btn',{staticClass:"d-none",attrs:{"id":"submit-article"},on:{"click":function($event){return _vm.onSubmit()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"delete-article"},on:{"click":function($event){return _vm.onDelete()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"print-report"},on:{"click":function($event){return _vm.printReportFromTopButton()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"move-to-parent"},on:{"click":function($event){return _vm.moveToParentFamily()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }