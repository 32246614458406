<template>
    <div class="pt-3 pl-2">
        <div class="multi-button">
            <button v-for="(item,index) in buttonArray" :key="index" @click="setModelValue(item)" :class="item == stockUnits ? 'active-button' : ''">
                x{{ item }}
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: "ClientPriceInput",
    data() {
        return {
            stockUnits: this.value,
            buttonArray: [1,2,4]
        };
    },
    props: {
        value: {
            default: 4,
        },
        marginRight: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        stockUnits(val) {
            this.$emit("input", val);
        },
        value(val) {
            this.stockUnits = val;
        },
    },
    methods:{
        setModelValue(value){
            this.stockUnits = value;
        }
    }
};
</script>
<style scoped>
.text-right >>> input {
    text-align: right;
    padding-right: 2px;

    width: 100%;
}

.multi-button {
    background-color: #eee;
    padding: 2px;
    border-radius: 8px;
    width: 100px !important;
}
.multi-button button {
    height: 35px;
    width: 32px;
    border: none;
    background-color: inherit;
}
.multi-button button:hover {
    background-color: #AACEF1;
}
.multi-button button:first-child {
    border-radius: 8px 0 0 8px;
}
.multi-button button:last-child {
    border-radius: 0 8px 8px 0;
}
.active-button{
    background-color: #1976d2 !important;
    color: white !important;
}
</style>
